import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faBookOpen, faBook } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { faTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons'

// library.add(faTwitter, faDiscord, faBookOpen, faBook)
const app = createApp(App)
// app.component('fontAwesome', FontAwesomeIcon)
app.use(store).use(router).mount('#app')

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ethereum: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    web3: any
  }
}
