import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PixelotMint from '@/views/PixelotMint.vue'
import PixelotWhitelistMint from '@/views/PixelotMintWhitelist.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: 'pixelot/:id',
        name: 'pixelot',
        component: () => import(/* webpackChunkName: "home" */ '../components/home/PixelotModal.vue'),
        meta: { transition: 'fade' }
      },
      {
        path: 'president/:id',
        name: 'president',
        component: () => import(/* webpackChunkName: "home" */ '../components/home/PresidentModal.vue'),
        meta: { transition: 'fade' }
      }]
  },
  {
    path: '/mint',
    name: 'mint',
    component: PixelotMint
  },
  {
    path: '/whitelistmint',
    name: 'whitelistmint',
    component: PixelotWhitelistMint
  },
  {
    path: '/roadmap',
    name: 'roadmap',
    component: () => import(/* webpackChunkName: "about" */ '../views/RoadmapView.vue')
  },
  {
    path: '/termsandconditions',
    name: 'termsandconditions',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsView.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'asdf',
    component: HomeView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
