import store from '@/store'
import pixelotNFT from '@/contracts/PixelotNFT.json'
import pixelotStaking from '@/contracts/PixelotStaking.json'
import pixeToken from '@/contracts/PixeToken.json'
import { ethers } from 'ethers'
import axios from 'axios'
import { MyPixelot } from '@/store/user.module'

export async function connectWallet(): Promise<string> {
  const params = [{ chainId: process.env.VUE_APP_CHAINID, rpcUrls: [process.env.VUE_APP_HTTPPROVIDER], chainName: process.env.VUE_APP_CHAINNAME, blockExplorerUrls: [process.env.VUE_APP_EXPLORER_ADDRESS], nativeCurrency: { name: process.env.VUE_APP_CONTRACT_COIN, decimals: 18, symbol: process.env.VUE_APP_CONTRACT_COIN } }]
  console.log({ chainId: process.env.VUE_APP_CHAINID, rpcUrls: [process.env.VUE_APP_HTTPPROVIDER], chainName: process.env.VUE_APP_CHAINNAME, blockExplorerUrls: [process.env.VUE_APP_EXPLORER_ADDRESS], nativeCurrency: { name: process.env.VUE_APP_CONTRACT_COIN, decimals: 18, symbol: process.env.VUE_APP_CONTRACT_COIN } })

  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x4' }]
    })
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params
        })
      } catch (addError) {
        // handle "add" error
      }
    }
  }
  const address = (await window.ethereum.request({ method: 'eth_requestAccounts' }))[0]
  window.ethereum.on('accountsChanged', async (accounts) => {
    store.commit('user/resetUser')
    console.log('accountsChange')
    store.commit('user/setAddress', accounts[0])
    GetUserData()
    GetNftData()
    GetTotalPixe()
    GetPresidents()
  })

  window.ethereum.on('chainChanged', (chainId) => {
    console.log('chainChanged')
    if (chainId !== process.env.REACT_APP_CHAINID) {
      store.commit('user/resetUser')
    }
  })

  await GetUserData()
  store.commit('user/setAddress', address)
  GetNftData()
  GetPresidents()
  return address
}

export async function disconnectWallet(): Promise<string> {
  store.commit('user/resetUser')
  store.commit('pixelot/setPresidents', [])
  return ''
}

export async function MintPublicSale (count): Promise<string> {
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
  const signer = provider.getSigner()
  const contract = new ethers.Contract(process.env.VUE_APP_CONTRACT_PIXELOT, pixelotNFT, signer)
  const from = (await provider.send('eth_requestAccounts', []))[0]
  const value = (await contract.tokenPrice()).mul(count)
  const overrides = {
    from,
    value
  }
  let a = 'success'
  try {
    await contract.mint(count, overrides)
  } catch (error) {
    a = error.data.message
  }
  await GetUserData()
  console.log(a)
  return a
}

export async function MintWhitelist (count, proof) {
  const proofA = []
  proof.forEach(element => {
    proofA.push(element.toLowerCase())
  })
  console.log(proofA)
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
  const signer = provider.getSigner()
  const contract = new ethers.Contract(process.env.VUE_APP_CONTRACT_PIXELOT, pixelotNFT, signer)
  const from = (await provider.send('eth_requestAccounts', []))[0]
  const value = (await contract.tokenPrice()).mul(count)
  const overrides = {
    from,
    value
  }
  console.log('whitelistmint')
  await contract.whitelistedMints(proofA, count, overrides)
  console.log('minting done')
  await GetUserData()
}

export async function GetUserData () {
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
  const signer = provider.getSigner()
  const contract = new ethers.Contract(process.env.VUE_APP_CONTRACT_PIXELOT, pixelotNFT, signer)
  const from = (await provider.send('eth_requestAccounts', []))[0]
  const balance = (await contract.balanceOf(from)).toNumber()
  const maxMainSale = (await contract.maxMainSale()).toNumber()
  const maxPresale = (await contract.maxPresale()).toNumber()
  const maxPerWallet = (await contract.maxPerWallet()).toNumber()
  GetTotalPixe()
  console.log({ maxMainSale, maxPerWallet, maxPresale })
  store.commit('pixelot/setContractData', { maxMainSale, maxPerWallet, maxPresale })
  store.commit('user/setBalance', balance)
}

export async function GetPresidents () {
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
  const from = (await provider.send('eth_requestAccounts', []))[0]
  const signer = provider.getSigner()
  const contract = new ethers.Contract(process.env.VUE_APP_CONTRACT_STAKING, pixelotStaking, signer)
  let value = await contract.getAllPresidents()
  value = value.map((p, index) => { return { id: index + 10000, address: p } })
  value = value.filter((p) => {
    return p.address !== '0x0000000000000000000000000000000000000000'
  })
  value.sort((x: { id: number, address: string }) => {
    console.log(x.address.toLowerCase() === from.toLowerCase())
    if (x.address.toLowerCase() === from.toLowerCase()) return -1
    else return 1
  })
  console.log(value)
  store.commit('pixelot/setPresidents', value)
}

export async function GetNftData () {
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
  const address = (await provider.send('eth_requestAccounts', []))[0]
  const url = process.env.VUE_APP_ALCHEMYENDPOINT + '/getNFTs/'
  let config = {
    method: 'get',
    url: `${url}?owner=${address}&contractAddresses[]=${process.env.VUE_APP_CONTRACT_PIXELOT}`
  }
  let response = await axios(config)
  const myPixelots = response.data.ownedNfts.map((x) => {
    return { id: parseInt(x.id.tokenId) }
  })
  config = {
    method: 'get',
    url: `${url}?owner=${address}&contractAddresses[]=${process.env.VUE_APP_CONTRACT_PRESIDENT}`
  }
  response = await axios(config)
  const myPresidents = response.data.ownedNfts.map((x) => {
    return { id: parseInt(x.id.tokenId) }
  })
  console.log(response)
  store.commit('user/setMyPixelots', [...myPixelots, ...myPresidents])
}

export async function GetNftStatus (id: number): Promise<MyPixelot> {
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
  const signer = provider.getSigner()
  const contract = new ethers.Contract(process.env.VUE_APP_CONTRACT_STAKING, pixelotStaking, signer)
  const value = await contract.stakes(id)
  let reward = 0
  console.log(value)
  if (value[1]) {
    reward = await contract.calculateRewardByTokenId(id)
  }
  store.commit('user/setMyPixelotData', { id, staked: !!value[1], dateStaked: new Date(value[0].toNumber() * 1000), reward })
  return { id, staked: false, dateStaked: new Date(), reward: 2000 }
}

export async function GetPresidentChoosenDate (id: number): Promise<Date> {
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
  const signer = provider.getSigner()
  const contract = new ethers.Contract(process.env.VUE_APP_CONTRACT_STAKING, pixelotStaking, signer)
  const value = await contract.chosenAt(id)
  const a = value.toNumber()
  return new Date(a * 1000)
}

export async function stakePixelot (id: number) {
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
  const signer = provider.getSigner()
  const contract = new ethers.Contract(process.env.VUE_APP_CONTRACT_STAKING, pixelotStaking, signer)
  const value = await contract.stake(id)
  const result = await provider.waitForTransaction(value.hash)
  return result
}

export async function stakePresident (id: number) {
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
  const signer = provider.getSigner()
  const contract = new ethers.Contract(process.env.VUE_APP_CONTRACT_STAKING, pixelotStaking, signer)
  const value = await contract.stakePresident(id)
  const result = await provider.waitForTransaction(value.hash)
  return result
}

export async function unstakePixelot (id: number) {
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
  const signer = provider.getSigner()
  const contract = new ethers.Contract(process.env.VUE_APP_CONTRACT_STAKING, pixelotStaking, signer)
  const value = await contract.unstake(id)
  const result = await provider.waitForTransaction(value.hash)
  return result
}

export async function unstakePresident (id: number) {
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
  const signer = provider.getSigner()
  const contract = new ethers.Contract(process.env.VUE_APP_CONTRACT_STAKING, pixelotStaking, signer)
  const value = await contract.unstakePresident(id)
  const result = await provider.waitForTransaction(value.hash)
  return result
}

export async function mintPresident (id: number) {
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
  const signer = provider.getSigner()
  const contract = new ethers.Contract(process.env.VUE_APP_CONTRACT_STAKING, pixelotStaking, signer)
  const value = await contract.mintAPresident(id)
  const result = await provider.waitForTransaction(value.hash)
  return result
}

export async function GetTotalPixe() {
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
  const signer = provider.getSigner()
  const contract = new ethers.Contract(process.env.VUE_APP_CONTRACT_PIXE, pixeToken, signer)
  const contractStaking = new ethers.Contract(process.env.VUE_APP_CONTRACT_STAKING, pixelotStaking, signer)
  const address = (await provider.send('eth_requestAccounts', []))[0]
  const value = await contract.balanceOf(address)
  const value2 = await contractStaking.calculateRewardPerUser(address)
  // console.log('pixe', value.toNumber() + value2.toNumber())

  const balance = ethers.utils.formatEther(value.add(value2))
  console.log(balance)
  store.commit('user/setPixeBalance', balance)
}
