import Vuex from 'vuex'
import { User, user } from './user.module'
import { Pixelot, pixelot } from './pixelots.module'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage
})

export interface State {
  user: User,
  pixelot: Pixelot
}

export default new Vuex.Store<State>({
  modules: {
    user,
    pixelot
  },
  plugins: [vuexLocal.plugin]
})
