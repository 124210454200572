export interface President {
  id: number,
  address: string
}

export interface Pixelot {
  maxPerWallet: number,
  maxMainSale: number,
  maxPresale: number,
  presidents: President[]
}

export const pixelot = {
  namespaced: true,
  state: () => ({
    maxPerWallet: 0,
    maxMainSale: 0,
    maxPresale: 0,
    presidents: []
  }),
  mutations: {
    setContractData (state: Pixelot, data: { maxPerWallet: number, maxMainSale: number, maxPresale: number }) {
      state.maxPerWallet = data.maxPerWallet
      state.maxMainSale = data.maxMainSale
      state.maxPresale = data.maxPresale
    },
    setPresidents (state: Pixelot, presidents: President[]) {
      state.presidents = presidents
    }
  }
}
