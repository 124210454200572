/* eslint-disable camelcase */
import axios from 'axios'
// import store from '@/store'

// axios.interceptors.response.use(
//   function(response) { return response },
//   function(error) {
//     if (error.response && error.response.status === 401) {
//       console.dir(error.response.status)
//       store.commit('user/logout')
//     }
//   })

// axios.interceptors.request.use(config => {
//   if (store.state.user.accessToken && store.state.user.accessToken !== '') {
//     config.headers.Authorization = 'Bearer ' + store.state.user.accessToken
//   }
//   return config
// })

export default {
  async getMerkleProof(address: string) {
    const a = await axios.get(process.env.VUE_APP_API_ADDRESS + '/getMerkleProof', { params: { account: address } })
    return a.data
  }
}
