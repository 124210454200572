export interface MyPixelot {
  id: number,
  reward: number,
  staked: boolean,
  dateStaked: Date,
}

export interface User {
  address: string,
  myPixelots: MyPixelot[],
  pixelotBalance: number,
  pixeBalance: number,
  mapShowMine: boolean,
  mapShowPresidents: boolean,
  listSelection: number
}

export const user = {
  namespaced: true,
  state: () => ({
    address: '',
    pixelotBalance: 0,
    pixeBalance: 0,
    mapShowMine: true,
    mapShowPresidents: true,
    listSelection: 1
  }),
  mutations: {
    setAddress (state: User, address: string) {
      state.address = address
    },
    switchListSelection (state: User, page: number) {
      state.listSelection = page
    },
    setBalance (state: User, pixelots: number) {
      state.pixelotBalance = pixelots
    },
    setMyPixelots (state: User, pxielots: MyPixelot[]) {
      state.myPixelots = pxielots
    },
    resetUser (state: User) {
      state.address = ''
      state.myPixelots = []
      state.pixeBalance = 0
    },
    setMyPixelotData (state: User, pixelot: MyPixelot) {
      const foundIndex = state.myPixelots.findIndex(x => x.id === pixelot.id)
      state.myPixelots[foundIndex] = pixelot
    },
    setPixeBalance (state: User, pixe: number) {
      state.pixeBalance = pixe
    },
    toggleMapShowMine(state: User) {
      state.mapShowMine = !state.mapShowMine
    },
    toggleMapShowPresidents(state: User) {
      state.mapShowPresidents = !state.mapShowPresidents
    }
  }
}
